
import { defineComponent, onMounted, ref } from "vue";
// import Gender from "@/components/reusable/Gender.vue";
// import RelationshipType from "@/components/reusable/RelationshipType.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "individual-client-relationship-information",
  components: {
    // Gender,
    // RelationshipType,
    ErrorMessage,
    Field,
    Form
  },
  data() {
    return {
      displayDates: false,
      relationshipDetails: {
        avatar: "",
        firstName: "",
        middleName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        meansOfIdentification: "",
        identificationNumber: "",
        identificationIssueDate: "",
        identificationExpiryDate: "",
        alternativePhoneNumber: "",
        dateOfBirth: "",
        address: "",
        password: ""
      }
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);

    const relationshipDetailsValidator = Yup.object().shape({
      firstName: Yup.string().required().label("First name"),
      middleName: Yup.string().notRequired().label("Middle name"),
      lastName: Yup.string().required().label("Last name"),
      phoneNumber: Yup.number().required().label("Phone number"),
      alternativePhoneNumber: Yup.string()
        .notRequired()
        .label("Alternative phone number"),
      dateOfBirth: Yup.date().required().label("Date of birth"),
      gender: Yup.mixed().required().label("Gender"),
      address: Yup.string().required().label("Address"),
      meansOfIdentification: Yup.mixed()
        .notRequired()
        .label("Means of identification"),
      identificationNumber: Yup.string()
        .notRequired()
        .label("Identification number"),
      identificationIssueDate: Yup.date()
        .notRequired()
        .label("Identification issue date"),
      identificationExpiryDate: Yup.date()
        .notRequired()
        .label("Identification expiry date"),
      password: Yup.string().min(8).required().label("Password")
    });

    const createRelationshipInformation = (values) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(variables.PROFILE_RELATION_INFORMATION, values)
              .then((response) => {
                variables.toastAlert(response.data.message, "success");
                window.location.reload();
              })
              .catch(function (error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Cooperative Society", ["Create"]);
    });

    return {
      submitButton1,
      createRelationshipInformation,
      relationshipDetailsValidator
    };
  },
  created() {
    //Set page title
    document.title = "Cooperative Society | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    handleImageUpload(event) {
      const selectedImage = event.target.files[0]; //get the first file

      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);

      reader.onload = (e) => {
        this.relationshipDetails.avatar = reader.result as string;
      };

      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    acceptNumberOnly(event) {
      variables.acceptNumberOnly(event);
    },
    identificationIssueDateAndExpiry(event) {
      const value = event.target.value;
      value == "Drivers License" || value == "International Passport"
        ? (this.displayDates = true)
        : (this.displayDates = false);
      const dateIssued = document.getElementById(
        "date-of-issue"
      ) as HTMLInputElement;
      const dateExpired = document.getElementById(
        "date-of-expiry"
      ) as HTMLInputElement;
      dateIssued.value = "";
      dateExpired.value = "";
    }
  }
});
